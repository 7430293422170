
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();




@import 'ngx-toastr/toastr';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$netfidelity_frontend-primary:mat.define-palette((
    50 : #e9f2ec,
    100 : #c9dfd0,
    200 : #a5cab1,
    300 : #80b492,
    400 : #65a47a,
    500 : #4a9463,
    600 : #438c5b,
    700 : #3a8151,
    800 : #327747,
    900 : #226535,
    A100 : #a7ffbe,
    A200 : #74ff99,
    A400 : #41ff73,
    A700 : #27ff60,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )

));
$netfidelity_frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$netfidelity_frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$netfidelity_frontend-theme: mat.define-light-theme((
  color: (
    primary: $netfidelity_frontend-primary,
    accent: $netfidelity_frontend-accent,
    warn: $netfidelity_frontend-warn
  )
));


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($netfidelity_frontend-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; overflow-y: hidden;}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;  }

.fxFlex-row {
  display: flex;
  flex-direction: row;
}

.fxFlex-column {
  display: flex;
  flex-direction: column;
}

.flex-25 {
  flex: 0 0 25%;
}

.flex-full {
  flex: 1;
}

h2{
  border-bottom: 1px solid #4a9463;
  color: #4a9463;
}
 *{
  ::-webkit-scrollbar{
    width: 12px;
  }
  ::-webkit-scrollbar-thumb{
    border-radius: 20px;
    background-color: #4a9463;
    border: 1px solid whitesmoke;
  }
 /*  body::-webkit-scrollbar-track{

  } */
}

.frame{
  display: flex;
  flex-direction: column;
  max-height: 100vh;
}

.sidenav-content{
  height: calc(100vh - var(--mat-toolbar-standard-height) - 40px) !important;
  padding: 20px
}


.blockui {
  position: relative;
}
.blockui .blockui-overlay {
  transition: all 0.3s ease;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
}
.blockui .blockui-overlay .spinner-border {
  height: 1.35rem;
  width: 1.35rem;
}
.blockui .blockui-message {
  display: flex;
  align-items: center;
  border-radius: 0.475rem;
  box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
  background-color: #fff;
  color: #7e8299;
  font-weight: 500;
  margin: 0 !important;
  width: auto;
  padding: 0.85rem 1.75rem !important;
}
.blockui .blockui-message .spinner-border {
  margin-right: 0.65rem;
}
h2{
  border-bottom: 1px solid #4a9463;
  color: #4a9463;
}
 *{
  ::-webkit-scrollbar{
    width: 12px;
  }
  ::-webkit-scrollbar-thumb{
    border-radius: 20px;
    background-color: transparent;
    border: 1px;
  }
}

.frame{
  display: flex;
  flex-direction: column;
  max-height: 100vh;
}

.sidenav-content{
  height: calc(100vh - var(--mat-toolbar-standard-height) - 40px) !important;
  padding: 20px
}
.blockui {
  position: relative;
}
.blockui .blockui-overlay {
  transition: all 0.3s ease;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
}
.blockui .blockui-overlay .spinner-border {
  height: 1.35rem;
  width: 1.35rem;
}
.blockui .blockui-message {
  display: flex;
  align-items: center;
  border-radius: 0.475rem;
  box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
  background-color: #fff;
  color: #7e8299;
  font-weight: 500;
  margin: 0 !important;
  width: auto;
  padding: 0.85rem 1.75rem !important;
}
.blockui .blockui-message .spinner-border {
  margin-right: 0.65rem;
}

.body {
  background-image: url('./assets/images/sfondo.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
}
 .background-auth {
  background-color: #f3f1e9 !important;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
}

.mdc-text-field--focused{
color: #4a9463;
}

.center-content{
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
